import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarAlt,
  faClipboard,
  faClock,
} from '@fortawesome/free-solid-svg-icons'
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'

library.add(faCalendarAlt, faClock, faClipboard)

const NotFound = React.lazy(() => import('./pages/NotFound'))

export const App = () => (
  <BrowserRouter>
    <Suspense fallback={<div className="waiter" />}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)
