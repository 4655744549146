import React, { FunctionComponent, useEffect } from 'react'
import { PageFooter, PageHeader } from '..'
import './page.scss'

type PageProps = {
  title?: string
  includeHeaderFooter?: boolean
  className?: string
}

export const Page: FunctionComponent<PageProps> = ({
  includeHeaderFooter = true,
  className,
  children,
  title,
}) => {
  useEffect(() => {
    document.title = title
      ? `${title} - osker.io`
      : `osker.io - Meeting management made simple`
  })

  const additionalClassNames = className ? ` ${className}` : ''
  return (
    <div className={`page-wrapper${additionalClassNames}`}>
      {includeHeaderFooter && <PageHeader />}
      {children}
      {includeHeaderFooter && <PageFooter />}
    </div>
  )
}
