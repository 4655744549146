import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import logo from 'src/images/logo.svg'
import { Page } from 'src/shared'
import './home.scss'

export default () => (
  <Page className="home" includeHeaderFooter={false}>
    <div className="title-container">
      <img src={logo} alt="osker.io logo" className="title-container__logo" />
    </div>
    <div className="content-container about-container">
      <h1 className="about-container__title">Meeting Management Made Simple</h1>
      <div className="about-container__topics">
        <div className="about-container__topic">
          <FontAwesomeIcon
            className="about-container__icon"
            icon={['fas', 'clock']}
          />
          <p>
            Keep meetings on track with Osker. Topics and speakers are kept on
            schedule, with long-running topics quickly moved offline.
          </p>
        </div>
        <div className="about-container__topic">
          <FontAwesomeIcon
            className="about-container__icon"
            icon={['fas', 'calendar-alt']}
          />
          <p>
            Osker lets you easily schedule meetings, invite team members, and
            get reminders of upcoming meetings.
          </p>
        </div>
        <div className="about-container__topic">
          <FontAwesomeIcon
            className="about-container__icon"
            icon={['fas', 'clipboard']}
          />
          <p>
            Osker makes sharing meeting notes and action items a breeze.
            Participants immediately have access to the important takeaways.
          </p>
        </div>
      </div>
    </div>
    <div>
      <span>
        Photos by{' '}
        <a href="https://unsplash.com/@youxventures?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
          You X Ventures
        </a>{' '}
        and{' '}
        <a href="https://unsplash.com/@wocintechchat?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
          Christina @ wocintechchat.com
        </a>
      </span>
    </div>
  </Page>
)
